<template>
  <div>
    <div class="father">
      <!-- 轮播 -->
      <topBar></topBar>
      <!--  -->
      <div class="jieshao_1">

        <div style="" class="jieshao_2">
          <el-timeline style="max-width: 600px">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
              :type="activity.type" :color="activity.color" :size="activity.size" :hollow="activity.hollow"
              @click="hanerto(activity.content)" style="cursor: pointer;">
              <span style="font-size: 0.8vw;">{{ activity.content }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 右边 -->
        <div class="jieshao_3" style="">
          <div style="width: 8.3vw;height: 7vh;">
            <img src="../../assets/img/tunadui.png" alt=""
              style="width: 100%;height: 100%;">
          </div>

          <div style="" class="jieshao_4">
           <div style="" class="jieshao_5">
            <!-- <div v-html="text"></div> -->
            新元素团队由三个方面构成：

基础团队

包括高级工程、资深设计师为代表的年轻研发团队和经验丰富的运营团队，是公司发展的基础和源动力。

高级顾问和合作团队

团队包括技术领域专家和教授、校企合作的研发团队。
            </div> 
            <div class="jieshao_6" style="">
              <img :src="textimg" alt="">
            </div>
          </div>

          <!-- <div class="jieshao_7" style="">
            公司专注于智慧旅游、大数据云端、政府教育等行业的研发、实施、运行维护以及文化创意设计等业务。公司先后被认定为江苏省首批重点文化科技企业、双软企业、国家中小型科技企业等。通过近几年研发与创意的积累，公司在旅游、教育及政府、物流领域中形成了以“互联网+”为基础和导向的产品系列。
          </div> -->
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
export default {
  name: 'gstuanDui',
  components: {
    topBar,
  },
  data() {
    return {
      textimg:"",
      text:"",
      name: "",//公司名称
      activities: [

        {
          content: '公司介绍',
          color: '#1c60c3',
        },
        {
          content: '公司业务',
          color: '#1c60c3',
        },
        {
          content: '公司团队',

          color: '#fff',
          type: 'primary',
          hollow: true,
        },
        {
          content: '公司资质',
          color: '#1c60c3',
        },
        {
          content: '公司文化',
          color: '#1c60c3',
        },
        {
          content: '',
          color: '#1c60c3',
        },
      ]
    }
  }, 
  created() {
    this.getlist()
  },
  methods: {
    hanerto(e) {
  

      if (e == '公司介绍') {
        this.$router.push({ name: 'jieShao' })
      }
      if (e == '公司业务') {
        this.$router.push({ name: 'gsyeWu' })
      }
      //   if(e=='公司团队'){
      //     this.$router.push({ name: 'gstuanDui' })
      //   }
      if (e == '公司资质') {
        this.$router.push({ name: 'gsziZhi' })
      }
      if (e == '企业文化') {
        this.$router.push({ name: 'gswenHua' })
      }
      if (e == '技术方向') {
        this.$router.push({ name: 'gsjiShu' })
      }
    },
    getlist() {
      this.$http.get('/door/getCategory/1').then(res => {
        this.id= res.data.data[2].id
   
        this.activities.forEach((activity, index) => {
          // 查找res.data.data中对应索引的元素
          const title = res.data.data[index].title;
          // 替换content字段
          activity.content = title;
        });
        this.gettext()
      })
    },
       //右边的文章
       gettext(){
      this.$http.get('/door/getArticleList', { params: { articleCat:this.id} }).then(res => {
              
                  this.text=res.data.rows[0].content
                  this.textimg=`${this.$baseUrl}${res.data.rows[0].contentImg}`
            })
    }

  }
}
</script>

<style lang="scss" scoped>
.father {
  width: 80vw;
  margin-left: 10vw;
}

.jieshao_1 {
  width: 75vw;
  height: 90vh;
  margin-left: 2.5vw;
  background-color: #edf2f6;
  padding-top: 3vh;
  display: flex;
  margin-top: 0vh;
}

.jieshao_2 {
  width: 6vw;
  margin-left: 6vw;
  margin-top: 3vh;
}

.jieshao_3 {
  width: 68%;
  height: 80vh;
  background-color: #fff;
  margin-left: 0vw !important;
  padding: 2vw;
}

.jieshao_4 {
  width: 100%;
  margin-top: 3vh;
  display: flex
}

.jieshao_5 {
  width: 55%;
  font-size: 16px;
  color: #2d2d2d;
  text-indent: 1.3vw;
  line-height: 4vh;
  float: left;
  margin-top: 2vh;
}

.jieshao_6 {
  width: 50%;
  height: 280px;
  margin-left: 2vw;
  img{
    width: 100%;
    height: 100%;
  }
}

.jieshao_7 {
  margin-top: 2vh;
  width: 90%;
  font-size: 16px;
  color: #2d2d2d;
  text-indent: 1.3vw;
  line-height: 4vh;
  float: left;
}
</style>