<template>
    <div style="position: relative;">
        <div class="father">

            <!-- 轮播 -->
            <topBar></topBar>
            <!-- 公司简介左边 -->
            <div class="abstract_left">
                <div class="left_list wow animate__animated animate__bounceInLeft">
                    <div v-for="item in textlist" :key="item" class="absstart_left1">
                        <div class="absstart_left2" @click="tonewInfo">
                            <img src="../assets/img/+.png" alt="">
                        </div>
                        <div style="width: 4vw;">
                            <div class="absstart_left3"> {{ item.month }}</div>
                            <div class="absstart_left4">{{ item.day }}</div>
                        </div>
                        <div class="absstart_left5">
                            <span class="left_title" @click="handertext(item.id)">{{ item.title }}</span>
                            <div class="left_text"> {{ item.description }}</div>
                        </div>
                    </div>
                </div>
                <!-- 公司简介右边 -->
                <div style=" width: 32vw;  margin-left: 6vw;"
                    class="right_list wow animate__animated animate__bounceInRight">
                    <div class="absstart_right2"> 公司简介/ABOUT</div>
                    <h1 class="absstart_right3">10年专注，成就卓越</h1>
                    <span style="color: #636363;font-size: 1vw;">江苏新元素 ——为您提供优质互联网服务</span>
                    <div class="absstart_right4" v-html=" jieshaoText "></div>
                    <button class="absstart_right5" @click="handerjieshao">详情介绍</button>
                </div>
            </div>
            <!-- 产品解决方案 -->
            <div class="fangan_1">
                <div class="fangan_2">
                    <div class="left_list wow animate__animated animate__bounceInLeft fangan_2_1">产品与行业解决方案</div>
                </div>
                <div class="fangan_3">
                    <div class="fangan_4">
                        <div class="childlist" v-for="item in chanpinglist" :key="item.id">
                            <div class="chanping_img" @click="tocpText(item)">
                                <img style="width: 100%;height: 100%;" :src="item.coverImg" alt="">
                            </div>
                            <div class="fangan_5">
                                <div class="fangan_title">{{ item.title }}</div>
                                <div class="fangan_6">{{ item.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--通用产品与服务 -->
                <div class="xinshang_1">
                    <div class="left_list wow animate__animated animate__bounceInLeft xinshang_1_1">通用产品与服务</div>
                </div>
                <div class="xinshang_2">
                    <div class="xinshang_3">

                        <div class="xinshang_4">
                            <div class="anli1" @click="Textinfo(0)">
                                <div class="mask"></div>
                                <!-- //标题 -->
                                <div class="card_title_num">
                                    <div class="card_bord_num">
                                        <span>{{ title1}}</span>
                                    </div>
                                    <div class="card_text_num">{{eng1}}</div>
                                </div>
                                <img style="width: 100%;height: 100%;" :src="anlilist1">
                            </div>

                            <div class="anli1" @click="Textinfo(1)">
                                <div class="mask"></div>
                                <!-- //标题 -->
                                <div class="card_title_num">
                                    <div class="card_bord_num">
                                        <span>{{ title2}}</span>
                                    </div>
                                    <div class="card_text_num">{{eng2}}</div>
                                </div>
                                <img style="width: 100%;height: 100%;" :src="anlilist2">
                            </div>
                        </div>

                        <div class="anli3" @click="Textinfo(2)">
                            <div class="mask"></div>
                            <!-- //标题 -->
                            <div class="card_title_center">
                                <div class="card_bord_center">
                                    <span>{{ title3}}</span>
                                </div>
                                <div class="card_text_center">{{eng3}}</div>
                            </div>

                            <img style="width: 100%;height: 100%;" :src="anlilist3">
                        </div>
                        <div>
                            <div class="anli4" @click="Textinfo(3)">
                                <div class="mask"></div>
                                <div class="card_title_center">
                                    <div class="card_bord_center" style="cursor: pointer;">
                                        <span>{{title4}}</span>
                                    </div>
                                    <div class="card_text_center">{{eng4}}</div>
                                </div>
                                <img style="width: 100%;height: 100%;" :src="anlilist4">
                            </div>
                            <div class="anli5" @click="Textinfo(4)">
                                <div class="mask"></div>
                                <div class="card_title_center">
                                    <div class="card_bord_center" style="cursor: pointer;">
                                        <span>{{ title5}}</span>
                                    </div>
                                    <div class="card_text_center">{{ eng5 }}</div>
                                </div>
                                <img style="width: 100%;height: 100%;" :src="anlilist5">
                            </div>
                        </div>
                    </div>
                    <div style="height: 3vh"></div>
                </div>
            
                <div class="kehu_3"></div>

            </div>


        </div>
        <!-- 导航 -->

    </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
import WOW from 'wowjs'

export default {
    name: 'homeComponent',
    components: {
        topBar,
    },
    data() {
        return {

            jieshaoText: '',
            textlist: [],//文章列表
            chanpinglist: [],//产品解决方案
            anlilist: [],
            title1: '',
            eng1:"",
            eng2:"",
            eng3:"",
            eng4:"",
            eng5:"",
            title2: '',
            title3: '',
            title4: '',
            title5: '',
            anlilist1: '',//案例欣赏
            anlilist2: '',//案例欣赏
            anlilist3: '',//案例欣赏
            anlilist4: '',//案例欣赏
            anlilist5: '',//案例欣赏
        }
    },
    mounted() {
        new WOW.WOW().init()
    },
    created() {
        this.getcard()
        this.getText()
        this.getanli()
        this.getjianjie()
    },
    methods: {

        expand(id) {
            this.isExpanded[id] = true;
        },
        shrink(id) {
            this.isExpanded[id] = false;
        },
        //获取文章
        getText() {
            //获取接口以后把时间转成英文首写模式
            this.$http.get('/door/getHomeArticleList').then(res => {
                const monthMap = {
                    1: 'Jan',
                    2: 'Feb',
                    3: 'Mar',
                    4: 'Apr',
                    5: 'May',
                    6: 'Jun',
                    7: 'Jul',
                    8: 'Aug',
                    9: 'Sep',
                    10: 'Oct',
                    11: 'Nov',
                    12: 'Dec'
                };
                this.textlist = res.data.rows.map(item => {
                    const date = new Date(item.createTime);
                    const month = date.getMonth() + 1; // 月份是从0开始计数的，所以要加1
                    const day = date.getDate();
                    const monthInitial = monthMap[month]; // 获取月份的英文首写形式
                    // 将截取到的日期和月份添加到对象中
                    return {
                        ...item,
                        month: monthInitial,
                        day: day
                    };
                });

            })
        },
        //公司简介右边
        getjianjie() {
            this.$http.get('/door/getArticleList', { params: { articleCat: 1 } }).then(res => {
                this.jieshaoText = res.data.rows[0].content

            })
        },
        //获取产品解决方案图片
        getcard() {
            this.$http.get('/door/getCategory/5',).then(res => {
                const nextid=res.data.data[0].id //获取列表第一个下标的id存本地，然后到toplist组件里里面取出来默认带出
                localStorage.removeItem('nextid')
                localStorage.setItem('nextid', nextid)
                this.chanpinglist = res.data.data.map(item => {
                    // 在这里对每个item的coverImg字段前面拼接'http地址'
                    const img = `${this.$baseUrl}${item.coverImg}`;
                    return { ...item, coverImg: img };
                });

            })
        },
        //获取通用产品与服务
        getanli() {
            this.$http.get('/door/getArticleList', { params: { articleCat: 8 } }).then(res => {
                const id = res.data.rows[0].id
                const articleCat = res.data.rows[0].articleCat
                localStorage.removeItem('id')
                localStorage.removeItem('articleCat')
                localStorage.setItem('id', id)
                localStorage.setItem('articleCat', articleCat)
                this.anlilist = res.data.rows.map(item => {
                    // 在这里对每个item的coverImg字段前面拼接'http地址'
                    const img = `${this.$baseUrl}${item.coverImg}`;
                    return { ...item, coverImg: img };
                });

                this.title1 = res.data.rows[0].title;
                this.title2 = res.data.rows[1].title;
                this.title3 = res.data.rows[2].title;
                this.title4 = res.data.rows[3].title;
                this.title5 = res.data.rows[4].title;

                this.eng1 = res.data.rows[0].remark;
                this.eng2 = res.data.rows[1].remark;
                this.eng3 = res.data.rows[2].remark;
                this.eng4 = res.data.rows[3].remark;
                this.eng5 = res.data.rows[4].remark;
                this.anlilist1 = this.anlilist[0].coverImg;
                this.anlilist2 = this.anlilist[1].coverImg;
                this.anlilist3 = this.anlilist[2].coverImg;
                this.anlilist4 = this.anlilist[3].coverImg;
                this.anlilist5 = this.anlilist[4].coverImg;
              
            })
        },
        Textinfo(index) {
            const id = this.anlilist[index].id
            const articleCat=this.anlilist[index].articleCat
            localStorage.setItem('text', '案例欣赏')
            window.open(this.$router.resolve({ path: `anLi`, query: { id: id, articleCat: articleCat } }).href, '_blank');

        },
        //公司介绍右边跳转
        handertext(id) {
            this.$router.push(`/dongtaiText?id=${id}`)
        },
        // 公司左边+号跳转
        tonewInfo() {
            localStorage.setItem('text', '新闻动态')
            window.open(this.$router.resolve({ name: 'dongTai' }).href, '_blank');

        },
        //跳转公司介绍
        handerjieshao() {
            localStorage.setItem('text', '新闻动态')
            window.open(this.$router.resolve({ name: 'jieShao' }).href, '_blank');

        },
        //产品方案点击事件
        tocpText(item) {
            const id=item.id
            const text = '解决方案';
            localStorage.setItem('text', text);
            const routeData = this.$router.resolve({ name: 'fangAn', query: { id:id } });
            window.open(routeData.href, '_blank');

        }

    }
}
</script>

<style lang="scss">
// 
* {
    margin: 0;
    padding: 0;
}

.father {

    width: 80vw;
    margin-left: 10vw;
}



.childlist {
    width: 15.5vw;
    // height: 300px;
    // background-color: green;

}




.anli3 {
    position: relative;
    width: 22vw;
    height: 54vh;
    overflow: hidden;

    .mask {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(26, 74, 138,0.8);
        /* 设置蒙版层颜色和透明度 */
        transition: top 0.3s;
    }

    .card_title_center {
        position: absolute;
        font-size: 1.4vw;
        font-weight: 500;
        padding: 2.5vh;

        color: #fff;
    }

    .card_bord_center {
        width: 14vw;
        height: 5vh;
        cursor: pointer;

        background-size: cover;
        background: url('http://www.jsxinyuansu.com/statics/gongsi/images/alxsx.png') left bottom no-repeat;

        &:hover {
            color: #bebcbcc7;
        }
    }

    .card_text_center {

        margin-top: 1vh;
        font-size: 0.7vw;
    }
}

.anli3:hover .mask {
    top: 0;
}

.anli4 {
    position: relative;
    overflow: hidden;
    width: 22vw;
    height: 27vh;

    .mask {
        position: absolute;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        background-color: rgba(26, 74, 138,0.8);
        /* 设置蒙版层颜色和透明度 */
        transition: right 0.3s;
    }

    .card_title_center {
        position: absolute;
        font-size: 1.4vw;
        font-weight: 500;
        padding: 2.5vh;

        color: #fff;
    }

    .card_bord_center {
        width: 14vw;
        height: 5vh;

        background-size: cover;
        background: url('http://www.jsxinyuansu.com/statics/gongsi/images/alxsx.png') left bottom no-repeat;

        &:hover {
            color: #bebcbcc7;
        }
    }

    .card_text_center {

        margin-top: 1vh;
        font-size: 0.7vw;
    }
}

.anli4:hover .mask {
    right: 0;
    /* 鼠标悬停时蒙版层从右往左出现 */
}


.anli5 {

    position: relative;
    overflow: hidden;
    width: 22vw;
    height: 27vh;

    .mask {
        position: absolute;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        background-color: rgba(26, 74, 138,0.8);
        /* 设置蒙版层颜色和透明度 */
        transition: right 0.3s;
    }

    .card_title_center {
        position: absolute;
        font-size: 1.4vw;
        font-weight: 500;
        padding: 2.5vh;

        color: #fff;
    }

    .card_bord_center {
        width: 14vw;
        height: 5vh;

        background-size: cover;
        background: url('http://www.jsxinyuansu.com/statics/gongsi/images/alxsx.png') left bottom no-repeat;

        &:hover {
            color: #bebcbcc7;
        }
    }

    .card_text_center {

        margin-top: 1vh;
        font-size: 0.7vw;
    }
}

.anli5:hover .mask {
    right: 0;
    /* 鼠标悬停时蒙版层从右往左出现 */
}



.abstract_left {
    display: flex;
    justify-content: center;
    // margin-left: 10vw;
    display: flex;
    margin-top: 2vw;
}

.absstart_left1 {
    // background-color: red;
    width: 23vw;
    display: flex;
    position: relative;
    margin-bottom: 4vh;
}

.absstart_left2 {
    width: 1vw;
    height: 2vh;
    cursor: pointer;
    position: absolute;
    right: 0
}

.absstart_left3 {
    width: 3.2vw;

    background-color: #2a6dbb;
    text-align: center;
    line-height: 2.7vw;
    font-size: 0.9vw;
    color: #fff;
}

.absstart_left4 {
    width: 3.2vw;
    background-color: #f7f7f7;
    text-align: center;
    line-height: 2.7vw;
    font-size: 1.5vw;
    font-weight: 800;
    color: #959595
}

.absstart_left5 {
    width: 18vw;
    padding-bottom: 1vh;
    padding-top: 1vh;
    padding-left: 0.8vw;
}

.absstart_right1 {
    width: 32vw;
    margin-left: 6vw;
}

.absstart_right2 {
    width: 32vw;
    height: 3vh;
    font-size: 1vw;
    color: #2a6dbb;
    line-height: 2vh;
    border-bottom: 1px solid #2a6dbb;
}

.absstart_right3 {
    margin-bottom: 1vh;
    width: 32vw;
    height: 7vh;
    font-size: 1.7vw;
    color: #1f5199;
    line-height: 7vh;
}

.absstart_right4 {
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 14px;
    margin-top: 1vh;
    color: #636363;

}

.absstart_right5 {
    cursor: pointer;
    width: 5vw;
    height: 5vh;
    background-color: #3498db;
    border-radius: 5px;
    font-size: 0.8vw;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 5vh;
    margin-left: 25vw;
    margin-top: 2vh;
    border: 0;
}

.fangan_1 {
    width: 75vw;
    margin-left: 2.5vw;
    margin-top: 1vw;
    background-color: #f7f7f7;
}

.fangan_2 {

    text-align: center;
    background-color: #f7f7f7;
    font-size: 1.5vw;
    height: 6vh;
    border-bottom: 1px solid #d7d4d4;
    padding-top: 2vh;
    display: flex;
    justify-content: center;
    color: #333333;

    .fangan_2_1 {
        width: 14vw;
        height: 6vh;
        border-bottom: 2px solid #e60012;
    }
}

.fangan_3 {

    background-color: #f7f7f7;
    display: flex;
    margin-top: 7vh;
}

.fangan_4 {

    width: 68vw;
    padding-left: 4vw;
    padding-right: 4vw;
    display: flex;
    justify-content: space-between;
}

.fangan_5 {
    overflow: hidden;
    height: 15vh;
    background-color: #fff;
    padding: 2vh;
}

.fangan_6 {
    width: 14vw;
    height: 13vh;

    font-size: 14px;
    color: #999999;
    margin-top: 1vh;
    display: block;
    line-height: 20px;
}

.xinshang_1 {
    text-align: center;
    background-color: #f7f7f7;
    font-size: 1.5vw;
    height: 60px;
    border-bottom: 1px solid #d7d4d4;
    padding-top: 4vh;
    display: flex;
    justify-content: center;
}

.xinshang_1_1 {
    width: 11vw;
    height: 6.4vh;
    
    border-bottom: 2px solid #e60012;
}

.xinshang_2 {
    width: 75vw;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    padding-bottom: 4vh;

    .xinshang_3 {

   
        // margin-left: 2vw;
        display: flex;
    }

    .xinshang_4 {
        width: 22vw;

        .anli1 {
            width: 22vw;

            height: 27vh;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .card_title {
            position: absolute;
            font-size: 1.4vw;
            font-weight: 500;
            padding: 2.5vh;

            color: #fff;
        }

        .card_bord {
            width: 14vw;
            height: 5vh;
            background-size: cover;
            margin-left: 10vw;
            background: url('http://www.jsxinyuansu.com/statics/gongsi/images/alxsx.png') left bottom no-repeat;

            &:hover {
                color: #bebcbcc7;
            }
        }

        .card_text {
            margin-top: 1vh;
            font-size: 0.7vw;
        }

        .card_title_num {
            position: absolute;
            font-size: 1.4vw;
            font-weight: 500;
            padding: 2.5vh;
            margin-left: 5.5vw;
            color: #fff;
        }

        .card_bord_num {
            cursor: pointer;
            width: 14vw;
            height: 5vh;
            text-align: right;
            background-size: cover;
            background: url('http://www.jsxinyuansu.com/statics/gongsi/images/alxsx1.png') left bottom no-repeat;

            &:hover {
                color: #bebcbcc7;
            }
        }

        .card_text_num {
            text-align: right;
            margin-top: 1vh;
            font-size: 0.7vw;
        }

        // 


        .mask {
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background-color: rgba(26, 74, 138,0.8);
            /* 设置蒙版层颜色和透明度 */
            transition: left 0.3s;
        }

        .anli1:hover .mask {
            left: 0;
         
            /* 鼠标悬停时蒙版层向右移动消失 */
        }


    }
}

.kehu_1 {
    text-align: center;
    background-color: #fff;
    font-size: 1.6vw;
    height: 60px;
    padding-top: 4vh;
}

.kehu_2 {
    height: 2vh;
    background-color: #fff;
}

.kehu_3 {
    height: 5vh;
    background-color: #fff;
}

.left_title {
    margin-top: 1vh;
    cursor: pointer;
    font-size: 0.84vw;
}

.left_text {
    margin-top: 1vh;
    color: #b7b7cc;
    font-size: 0.9vw;
}

.fangan_title {
    font-size: 18px;
}

.chanping_img {
    width: 15.5vw;
    height: 22vh;
    overflow: hidden;
    position: relative;
    /* 添加相对定位 */
}

.chanping_img img {
    width: 100%;
    /* 图片宽度100%填充父容器 */
    height: 100%;
    /* 图片高度100%填充父容器 */
    transition: transform 0.5s;
}

.chanping_img:hover img {
    transform: scale(1.1);
}
</style>