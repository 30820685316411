<template>
    <div>
        <!-- 底部 -->
        <div class="bottom_1">
            <div style="" class="bottom_2">
                <div class="bottom_left">
                    <div style="" class="bottom_3">
                        <div class="bottom_4">
                        </div>
                        <span style=" color:#b5b5b5;font-size: 0.7vw;"> 业务电话：0518/ </span> <span
                            style="font-size: 1vw;color: #3498db;">81883980</span>
                    </div>

                    <div style="" class="bottom_5">
                        <div class="bottom_6" style="">
                        </div>
                        <span style=" color:#b5b5b5;font-size: 0.7vw;"> 业务QQ：2020674551</span>
                    </div>

                    <div style="" class="bottom_7">
                        <div class="bottom_8" style="">
                        </div>
                        <span style=" color:#b5b5b5;font-size: 0.7vw;"> E-mail：jsxys@jsxinyuansu.com </span>
                    </div>
                </div>

            </div>

            <div class="bottom_9" style="">
                <img src="../assets/img/eriweima.png" alt="">
            </div>
        </div>

        <div style="" class="bottom_10">
            <div class="bottom_11" style="">Copyright © 2013-2020 江苏新元素数字科技有限公司</div>
            <div class="bottom_12">苏公网安备 32070402010023号 苏ICP备11042316号-1</div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'homeBottom'
}
</script>

<style lang="scss" scoped>
.bottom_1 {
    width: 75vw;
    height: 20vh;
    background-color: #333333;
    display: flex;

}

.bottom_2 {
    width: 15vw;
    height: 20vh;
    margin-left: 15vw
}

.bottom_3 {

    width: 15vw;
    flex: 1;
    display: flex;
    align-items: center;
}

.bottom_4 {
    width: 1.5vw;
    height: 3.2vh;
    background-image: url(./../assets/img/dianhua.png);
    background-size: cover;
    margin-right: 0.5vw;
}

.bottom_left {
    flex-direction: column;
    display: flex;
    height: 20vh;
}

.bottom_5 {
    width: 15vw;
    flex: 1;
    display: flex;
    align-items: center;
}

.bottom_6 {
    width: 1.5vw;
    height: 3.2vh;
    background-image: url(../assets/img/qq.png);
    background-size: cover;
    margin-right: 0.5vw;
}

.bottom_7 {
    width: 15vw;
    flex: 1;
    display: flex;
    align-items: center;
}

.bottom_8 {
    width: 1.5vw;
    height: 3.2vh;
    background-image: url(../assets/img/youxiang.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 0.5vw;
}

.bottom_9 {

    width: 7.4vw;
    height: 15.5vh;
    background-color: #fff;
    margin-left: 15vw;
    margin-top: 2vh;
    background-image: url();
    background-size: cover;

    img {
        width: 100%;
        height: 100%;
    }
}

.bottom_10 {
    width: 75vw;
    height: 4vh;
    background-color: #242424;
    display: flex;
}

.bottom_11 {

    width: 30vw;
    color: #b5b5b5;
    align-items: center;
    display: flex;
    font-size: 0.7vw;
    margin-left: 15vw;
}

.bottom_12 {
    font-size: 0.7vw;
    height: 4vh;
    color: #b5b5b5;
    align-items: center;
    display: flex;
    margin-left:-2vw;
}
</style>