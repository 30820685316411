<template>
  <div>
    <div class="father">
      <!-- 轮播 -->
      <topBar></topBar>
      <!--  -->
      <div class="jieshao_1">


        <div style="" class="jieshao_2">
          <el-timeline style="max-width: 600px">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
              :type="activity.type" :color="activity.color" :size="activity.size" :hollow="activity.hollow"
              @click="hanerto(activity.content)" style="cursor: pointer; ">
              <span style="font-size: 0.8vw;">{{ activity.content }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 右边 -->
        <div class="jieshao_3">
          <div class="right_logo">
            <img src="@/assets/img/jishu.png" alt="">
          </div>

          <div style="" class="jieshao_4">
           <div style="" class="jieshao_5">
            <!-- <div v-html="text"></div> -->
             <span class="font">技术架构</span> <br>

江苏新元素数字科技有限公司的研发平台建立在创新的技术架构之上，旨在推动技术边界，实现产品与服务的卓越性能和可靠性。

技术哲学前沿融合：我们采用前沿技术，融合最新的编程范式和设计思想，确保技术解决方案的先进性和前瞻性。<br>

智能自动化：通过自动化工具和流程，我们实现研发效率的最大化，同时保持代码质量和系统稳定性。<br>





 
            </div> 
            <div class="jieshao_6" style="">
              <img :src="textimg" alt="">
            </div>
          </div>

           <div class="jieshao_7" style="">
           <span class="font">创新引擎</span> <br> 动态研发环境：我们的环境支持快速迭代和持续创新，以适应不断变化的市场需求和技术进步。

模块化设计：采用模块化设计理念，我们的技术栈能够灵活组合，以支持多样化的项目需求。<br>

 <span class="font">技术生态</span> <br>

开放兼容：我们的研发平台支持开放标准和协议，确保与各种技术生态系统的无缝集成。

安全基石：安全是我们研发的首要原则，我们的技术栈包括了多层安全措施，保护数据和系统不受威胁。<br>

  <span class="font">研发动力</span> <br>

高效协作：我们的研发平台促进团队成员之间的高效协作，通过共享知识、工具和最佳实践，加速创新过程。

持续学习：我们鼓励团队成员持续学习最新的技术趋势，以保持我们解决方案的相关性和竞争力。

未来导向

技术预见：我们不断探索和研究新兴技术，如等，以预见并塑造技术的未来。

创新孵化：我们的研发平台是一个创新孵化器，能够将创意转化为实际的产品和解决方案。 
          </div> 
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
export default {
  name: 'gsjiShu',
  components: {
    topBar,
  },
  data() {
    return {
      textimg:"",
      text:"",
      name: "",//公司名称
      activities: [

        {
          content: '',
          color: '#1c60c3',
          
        },
        {
          content: '',
          color: '#1c60c3',
        },
        {
          content: '',
          color: '#1c60c3',

        },
        {
          content: '',
          color: '#1c60c3',
        },
        {
          type: 'primary',
          hollow: true,
          content: '',
          color: '##fff',
        },
        {
          content: '',
          color: '#1c60c3',
        },
      ]
    }
  },
  created() {
    this.getlist()
    this.gettext()
  },
  methods: {
    hanerto(e) {
    

      if (e == '公司介绍') {
        this.$router.push({ name: 'jieShao' })
      }
      if (e == '公司业务') {
        this.$router.push({ name: 'gsyeWu' })
      }
      if (e == '公司团队') {
        this.$router.push({ name: 'gstuanDui' })
      }
      if (e == '公司资质') {
        this.$router.push({ name: 'gsziZhi' })
      }
      if (e == '企业文化') {
        this.$router.push({ name: 'gswenHua' })
      }
      if (e == '技术方向') {
        this.$router.push({ name: 'gsjiShu' })
      }
    },
    //获取左边的时间线
    getlist() {
      this.$http.get('/door/getCategory/1').then(res => {
        this.id= res.data.data[4].id
   
        this.activities.forEach((activity, index) => {
          // 查找res.data.data中对应索引的元素
          const title = res.data.data[index].title;
          // 替换content字段
          activity.content = title;
        });
      this.gettext()
      })
    },
    //右边的文章
    gettext(){
      this.$http.get('/door/getArticleList', { params: { articleCat:this.id} }).then(res => {
        
                  this.text=res.data.rows[0].content
                  this.textimg=`${this.$baseUrl}${res.data.rows[0].contentImg}`
            })
    }
  }
} 
</script>

<style lang="scss" scoped>
.font{
  font-size: 1vw;
  font-weight: 600;
}
.father {
  width: 80vw;
  margin-left: 10vw;

}

.jieshao_1 {

  width: 75vw;
  height: 135vh;
  margin-left: 2.5vw;
  background-color: #edf2f6;
  padding-top: 3vh;
  display: flex;
  margin-top: 0vh;
}

.jieshao_2 {
  width: 6vw;
  margin-left: 6vw;
  margin-top: 3vh;
}

.jieshao_3 {
  width: 68%;
  height: 125vh;
  background-color: #fff;
  margin-left: 0vw !important;
  padding: 2vw;

}

.jieshao_4 {
  width: 100%;
  margin-top: 3vh;
  display: flex
}

.jieshao_5 {
  width: 55%;
  font-size: 16px;
  color: #2d2d2d;
  // text-indent: 1.3vw;
  line-height: 4vh;
  float: left;
  margin-top: 2vh;
}

.jieshao_6 {
  width: 40%;
  height: 28vh;
  margin-left: 1vw;
  img{
    width: 100%;
    height: 100%;
  }
}

.jieshao_7 {
  margin-top: 2vh;
  width: 90%;
  font-size: 16px;
  color: #2d2d2d;

  line-height: 4vh;
  float: left;
}

.right_logo {
  width: 9vw;
  height: 7vh;
  

  img {
    width: 100%;
    height: auto;
  }
}
</style>