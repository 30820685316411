<template>
  <div>
    <div style="margin-left: 10vw;">
      <topBar></topBar>
    </div>

    <!-- 主体 -->
    <div class="info">
      <div style="display: flex">
        <div>
          <!-- 标题 -->
          <div class="info-left">
            <p class="title-one">联系我们</p>
            <span class="title-two">Contact</span>
          </div>
          <!-- 电话那几个p标签 -->
          <div class="phone">
            <div class="phone-top">
              <div class="phone-list">
                <span>电话:</span>
                <p>0518-81883980</p>
              </div>

              <div class="phone-list">
                <span>传真:</span>
                <p> 0518-81883980</p>
              </div>

              <div class="phone-list">
                <span>邮件:</span>
                <p> jsxys@jsxinyuansu.com</p>
              </div>

              <div class="phone-list">
                <span>网站:</span>
                <a href="www.jsxinyuansu.com"
                  style="color: #551A8B;text-decoration:none">www.jsxinyuansu.com</a>
              </div>


              <div class="phone-list">
                <p>
                <span style="width: 4vw;">地址:</span>
               
                  连云港市海州区朝阳东路32-6东盛阳光大厦A座3203 </p>
              </div>

            </div>
            <!-- 下半部分 -->
            <div class="phone-bottom">
              <div class="phone-list-1">

                <span>Tel:</span>
                <p>0518-81883980</p>
              </div>

              <div class="phone-list-1">
                <span style="  width: 3.5vw;">E-mail:</span>
                <p> jsxys@jsxinyuansu.com</p>
              </div>


              <div class="phone-list-1">
                <p>
                  <span  style="  width: 4vw;">Address:</span>
                
                  3203, Building A, Dongsheng Sunshine Building, 32-6 East Chaoyang Road, Haizhou District, Lianyungang City
                </p>

              </div>

            </div>

          </div>
        </div>

        <div class="baidu" id="map">
          <baidu-map class="bm-view" :zoom="18" :center="{ lng: 119.218592, lat: 34.597625 }" ref="map">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
            <bm-marker :position="{ lng: 119.218592, lat: 34.597625 }" :dragging="true" @click="infoWindowOpen"
              animation="BMAP_ANIMATION_BOUNCE">
              <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen"
                style=" font-size: 12px;padding-top: 1vh;">连云港市海州区朝阳东路32-6东盛阳光大厦A座3203</bm-info-window>
              <bm-label content="江苏新元素数字科技有限公司" :position="{ lng: 119.216592, lat: 34.57625 }" :offset="[0, 0]"
                :labelStyle="{ color: 'black', fontSize: '12px' }" title="Hover me" />
            </bm-marker>

          </baidu-map>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar.vue'


export default {
  name: 'gsPhone',
  components: {
    topBar
  },
  data() {
    return {
      show: true
    }
  },
  mounted() {
    // 确保map实例已经加载完成
    this.$nextTick(() => {
      // 获取map实例（这里假设你使用了ref="map"）

      // if (!map) {
      //   // 启用鼠标滚轮缩放（通常这是默认启用的，所以这行代码可能是多余的）
      //   map.enableScrollWheelZoom(true);
      // }
    });
  },
  methods: {
    infoWindowClose() {
      this.show = false
    },
    infoWindowOpen() {
  
      this.show = true
    }
  }


}
</script>

<style lang="scss" scoped>
.bm-view {
  width: 100%;
  height: 100%;
}

.baidu {
  width: 40vw;
  border: 0.2px solid #555555;
  margin-left: 5vw;
  height: 46vh;
  margin-top: 9vh;
}

.info {
  padding-top: 5vh;
  width: 75vw;
  height: 70vh;
  background-color: #edf2f6;
  margin-left: 12.5vw;
}

.title-one {
  font-size: 26px;
  color: #2A6DBB;
  padding-left: 20px;
}

.title-two {
  display: block;
  font-size: 18px;
  color: #2A6DBB;
  padding-left: 34px;
}

.info-left {
  margin-left: 8vw;
}

.phone {
  margin-top: 4vh;
  margin-left: 9vw;
  width: 18vw;

}

.phone-top {
  display: flex;
  flex-direction: column;
  height: 180px;
width: 20vw;
  // background-color: red;
 
}

.phone-bottom {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;

  height: 120px;

  // background-color:red;
  P {

    // margin-left: 0.5vw;
    font-size: 16px;
    color: #888888;
    line-height: 26px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  span {
    width: 3.5vw;
    font-size: 18px;
    color: #555555;
  }
}

.phone-list {
  height: 4vh;
  display: flex;

  span {
    width: 3vw;
    color: #555555;
    font-size: 18px;
  }

  p {
    font-size: 16px;
    color: #888888;
  }
}
.phone-list-1{
  height: 4vh;
  display: flex;

  span {
   width: 2vw;
    color: #555555;
    font-size: 18px;
  }

  p {
    font-size: 16px;
    color: #888888;
  }
}
</style>